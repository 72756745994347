<template>
	<div class="flex justify-between mb-3">
		<v-button @click="back" class="btn btn-small btn-default"
			><span v-html="back_icon"></span> Back to search</v-button
		>
	</div>

	<ProspectView :prospectId="resultId" />
</template>

<script>
import Button from "@/components/ui/Button";
import ProspectView from "@/components/projects/prospects/ProspectView";
import { mapGetters } from "vuex";

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>`;

export default {
	created() {},

	mounted() {},

	updated() {},

	components: {
		// Tab,
		// Tabs,
		"v-button": Button,
		ProspectView,
	},

	props: ["teamId", "resultId"],

	data() {
		return {
			back_icon,
		};
	},

	methods: {
		back() {
			this.$router.back();
		},
	},

	watch: {},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
